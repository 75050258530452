exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".radio-button_radio-button-group_1FwJR {\n    margin-top: 0.5rem;\n\tdisplay: -webkit-box;\n\tdisplay: -webkit-flex;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n}\n\n.radio-button_radio-button-item_1EeG5 {\n    font-size: 0.9rem;\n    font-weight: bold;\n    cursor: pointer;\n    padding: 0rem 0.5rem;\n}", ""]);

// exports
exports.locals = {
	"radio-button-group": "radio-button_radio-button-group_1FwJR",
	"radioButtonGroup": "radio-button_radio-button-group_1FwJR",
	"radio-button-item": "radio-button_radio-button-item_1EeG5",
	"radioButtonItem": "radio-button_radio-button-item_1EeG5"
};